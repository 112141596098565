import React from 'react';
import './about.css';

function About() {
  return (
    <div className="main-content">
      <h1>About Page</h1>
      <p>This is the about page of the travel blog.</p>
    </div>
  );
}

export default About;
